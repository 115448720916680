<template>
  <ul class="px-3 text-sm">
    <li class="flex w-full justify-between">
      <span>Product Total</span>
      <span>1499&euro;</span>
    </li>
    <li class="flex w-full justify-between">
      <span>Discount</span>
      <span>0&euro;</span>
    </li>
    <li class="flex w-full justify-between pb-1 border-b border-black">
      <span>Shipping</span>
      <span>25,00&euro;</span>
    </li>
    <li class="flex w-full justify-between mt-1 text-normal">
      <span>Total</span>
      <span>{{ (1499 + 25).toFixed(2).replace('.', ',') }}&euro;</span>
    </li>
  </ul>
</template>
