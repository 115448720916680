import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/main.css';
// Global components

import MainCard from '@/components/cards/MainCard';

// directives

import { ClickOutside } from '@/directives/click-outside.js';

const app = createApp(App)
  .use(store)
  .use(router)
  .component('MainCard', MainCard)
  .directive('click-outside', ClickOutside)
  .mount('#app');
