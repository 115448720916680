<template>
  <div class="divide-y divide-black">
    <h1 class="text-center text-lg">Your Cart</h1>
    <div class="pt-3 md:px-3">
      <ul>
        <slot></slot>
        <template v-for="(item, i) in items" :key="i">
          <li>
            <slot name="shopProducts" :item="item"></slot>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartTable',
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
