<template>
  <div class="page-wrap">
    <header>
      <slot name="header" />
    </header>
    <main id="main" class=" mt-20 transition-opacity delay-200 bg-primary">
      <slot></slot>
    </main>
    <footer id="contact" class="px-4 md:px-0 bg-primary">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'LayoutMain',
};
</script>
