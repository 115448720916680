<template>
  <article class="relative p-3 mb-3 border border-black">
    <header class="flex items-center justify-end border-b border-black py-1">
      <button class="self-end close-button"></button>
    </header>
    <div class="pt-3 flex ">
      <figure
        class="w-24 h-24 p-3 mr-3 flex items-center justify-center bg-white"
      >
        <img :src="item.img" alt="" />
      </figure>
      <div class="relative flex flex-col justify-between">
        <div>
          <p class="text-sm">{{ item.name }}</p>
          <div class="text-xs italic">
            <span>{{ item.price }}&euro;</span>
          </div>
        </div>

        <div>
          <p class="text-xs italic mb-1">Quantity:</p>
          <div class="flex items-center self-baseline">
            <button
              @click="$emit('changeQuantity', item.quantity - 1)"
              class="w-8 bg-white p-1 mr-1"
            >
              -
            </button>
            <div class="px-3 w-8 h-8 flex items-center">
              <span class="absolute"> {{ item.quantity }}</span>
            </div>
            <button
              @click="$emit('changeQuantity', item.quantity + 1)"
              class="w-8 bg-white p-1 flex-grow-0"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute bottom-2 right-3">
      <div class="flex justify-between items-baseline">
        <h3 class="text-xs italic mr-3 hidden sm:block">Subtotal:</h3>
        <span class="block text-lg"
          >{{ item.price * item.quantity }}&euro;</span
        >
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'CartProductCard',
  emits: ['changeQuantity'],
  props: {
    item: {
      type: Object,
    },
  },
};
</script>
