<template>
  <div
    v-for="review in _reviews"
    :key="review.author"
    class="mb-6 md:mb-0 w-full sm:w-8/12 mx-auto md:w-4/12 border border-black flex flex-col"
  >
    <div class="p-4 flex-1">
      <p>
        {{ review.content }}
      </p>
    </div>
    <div class="flex items-center h-20 px-4 bg-white">
      <div class="flex-1">
        {{ review.author }}
      </div>
      <div to="/shop/catalogue" class="w-12 h-12">
        <i class="flex h-full items-center justify-center">
          <img :src="review.img" alt="" />
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import reviews from '@/static/reviews.json';
import { markRaw } from 'vue';

export default {
  name: 'ReViews',
  setup() {
    return {
      _reviews: markRaw(reviews),
    };
  },
};
</script>
