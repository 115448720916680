<template>
  <div
    id="navbar"
    class="fixed top-0 w-full block pr-2 pl-2 lg:pl-0 border-b border-black border-opacity-20 shadow-sm bg-primary"
  >
    <div class="flex items-baseline justify-between">
      <button
        @click="showMobileNav = !showMobileNav"
        class="lg:hidden border-t border-b border-black px-2 py-1"
      >
        <span v-if="!showMobileNav">Menu</span>
        <div v-else class="relative">
          <span class="text-transparent">Menu</span>
          <span class="absolute left-1/2 transform -translate-x-1/2">X</span>
        </div>
      </button>
      <div
        class="p-4 text-2xl md:text-5xl tracking-wide flex-1 text-center lg:text-left"
      >
        <router-link to="/">1210BERLIN</router-link>
      </div>
      <ul class="lg:flex hidden">
        <li v-for="a in anchors" :key="a.name" class="px-4">
          <router-link :to="a.path">{{ a.name }}</router-link>
        </li>
      </ul>
      <template v-if="showMobileNav">
        <div
          style="backgroundcolor: var(--bg-base)"
          class="fixed top-20 left-0 bottom-0 right-0"
        >
          <ul class="h-full flex flex-col justify-around">
            <li
              @click="showMobileNav = !showMobileNav"
              v-for="a in anchors"
              :key="a.name"
              class="px-4"
            >
              <router-link :to="a.path">{{ a.name }}</router-link>
            </li>
          </ul>
        </div>
      </template>
      <router-link :to="{ name: 'Cart' }" class="border border-black px-2 py-1">
        Cart | {{ itemsInCart() }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useCartControls } from '@/composable/cart';

export default {
  setup() {
    const showMobileNav = ref(false);
    const { itemsInCart } = useCartControls();

    const anchors = [
      {
        name: 'All Products',
        path: '/shop/catalogue',
        children: [
          {
            name: 'Turntable Builder',
            path: '/shop/turntable-builder',
          },
          {
            name: 'Catalogue',
            path: '/shop/catalogue',
          },
          {
            name: 'Rentals',
            path: '/shop/rentals',
          },
        ],
      },
      {
        name: 'Configurator',
        path: '/shop/turntable-configurator',
        children: [
          {
            name: 'Turntable Builder',
            path: '/shop/turntable-builder',
          },
          {
            name: 'Catalogue',
            path: '/shop/catalogue',
          },
          {
            name: 'Rentals',
            path: '/shop/rentals',
          },
        ],
      },
      {
        name: 'Service',
        path: '/service',
        children: [
          {
            name: 'Pricelist',
            path: '/#service',
          },
          {
            name: 'Service Packs',
            path: '/service',
          },
          {
            name: 'Remote service',
            path: '/service#remote',
          },
        ],
      },
      {
        name: 'Rentals',
        path: '/shop/rentals',
        children: [
          {
            name: 'Pricelist',
            path: '/#service',
          },
          {
            name: 'Service Packs',
            path: '/service',
          },
          {
            name: 'Remote service',
            path: '/service#remote',
          },
        ],
      },
      {
        name: 'Legacy',
        path: '/legacy',
      },
      {
        name: 'Contact',
        path: '/#contact',
      },
    ];

    return { showMobileNav, itemsInCart, anchors };
  },
};
</script>

<style>
#navbar {
  z-index: 3000;
}
</style>
