<template>
  <div>
    <div id="hero" class="relative mb-12">
      <img
        class="opacity-40 w-full hidden sm:block"
        src="@/assets/img/hero.jpg"
        alt=""
      />
      <img
        class="opacity-40 w-full sm:hidden"
        src="@/assets/img/hero-sm.jpg"
        alt=""
      />
      <p
        class="hidden sm:block absolute top-1/2 left-1/2 text-xl sm:text-4xl md:text-6xl lg:text-8xl transform -translate-x-1/2 -translate-y-1/2 whitespace-nowrap"
      >
        LEGENDS LIVE FOREVER
        <span class="block text-base md:pl-2">{{
          'completely refurbished technics turntables and custom builds'.toUpperCase()
        }}</span>
        <router-link
          to="/shop/catalogue"
          class="inline-block text-3xl mt-8 bg-white px-4 py-2"
        >
          SHOP NOW
        </router-link>
      </p>
      <p class="sm:hidden absolute top-1/2 text-2xl transform -translate-y-1/2">
        LEGENDS LIVE FOREVER
        <span class="block text-base md:pl-2">{{
          'completely refurbished technics turntables and custom builds'.toUpperCase()
        }}</span>
        <router-link
          to="/shop/catalogue"
          class="inline-block text-3xl mt-8 bg-white px-4 py-2"
        >
          SHOP NOW
        </router-link>
      </p>
    </div>
    <div class="w-11/12 xl:w-10/12 mx-auto">
      <section id="overview" class="lg:flex gap-6">
        <div
          class="relative w-full lg:w-6/12 sm:flex lg:flex-col gap-6 lg:gap-0 justify-between lg:justify-center lg:items-center text-justify px-2 mb-6"
        >
          <p class="mb-2 sm:w-6/12 lg:w-full">
            We provide the repair, parts supply, rental and sales of carefully
            refurbished Technics turntables since 2011- the year Technics/
            Panasonic ceased the production of the history making direct drive
            1200/1210 series turntables (MK2-6).
          </p>
          <p class="sm:w-6/12 lg:w-full">
            Apart from turntables we also offer servicing and sales for a range
            of mixers, MPC’s and other sound equipment and of course everything
            you might possibly need for playing records!
          </p>
        </div>
        <nav
          role="navigation"
          aria-label="quick access"
          class="flex justify-between flex-wrap quick-nav w-full lg:w-6/12 mx-auto"
        >
          <div class="mb-6 nav-card">
            <main-card
              :img="require('@/assets/img/technics-build.png')"
              routeTo="/shop/turntable-configurator"
            >
              Configure your Technics Turntable
            </main-card>
          </div>
          <div class="mb-6 nav-card">
            <main-card
              :img="require('@/assets/img/audio-equip.jpg')"
              routeTo="/shop/catalogue"
            >
              Audio Equipment, spare parts and accessories
            </main-card>
          </div>
          <div class="mb-6 nav-card">
            <main-card
              :img="require('@/assets/img/repairs.jpg')"
              routeTo="/service"
            >
              Service and Repairs
            </main-card>
          </div>
          <div class="mb-6 nav-card">
            <main-card
              :img="require('@/assets/img/rentals.jpg')"
              routeTo="/shop/rentals"
            >
              Rentals
            </main-card>
          </div>
        </nav>
      </section>
      <section id="contact">
        <h2 class="text-4xl lg:text-center mb-4 py-4">
          Come check out our store and workshop in Berlin, Neukölln
        </h2>
        <div class="flex flex-wrap md:flex-nowrap gap-6">
          <div class="w-full md:w-6/12 order-2 md:order-1">
            <div class="mb-2 md:mb-0 border border-black">
              <div class="p-4">
                <p>Shop and Workshop Hours: TUE/THU/FRI: 14 - 19, SAT: 14-16</p>
                <br />
                <p>Telephone: +49 30 91486168</p>
                <br />
                <p>Email: info@1210berlin.de</p>
              </div>
              <div class="flex items-center h-20 px-4 bg-white">
                <div class="flex-1">Find us</div>
                <a
                  href="https://goo.gl/maps/FgjhYn3R8ecHrNSr7"
                  target="_blank"
                  class="flex-0 w-12 h-12 border border-black"
                >
                  <i class="flex h-full items-center justify-center">
                    <svg
                      class="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                    >
                      <path
                        fill="#000"
                        fill-rule="nonzero"
                        d="M.146 3.611a.606.606 0 0 1 .437-.177h11.412L9.515.994C9.225.736 9.219.47 9.498.2c.28-.271.553-.265.82.018l3.5 3.394a.343.343 0 0 1 .147.177.65.65 0 0 1 0 .424.343.343 0 0 1-.146.177l-3.5 3.394c-.268.283-.541.29-.82.018-.28-.271-.274-.536.018-.796l2.479-2.44H.583a.577.577 0 0 1-.419-.158A.542.542 0 0 1 0 4c0-.165.049-.295.146-.389z"
                      ></path>
                    </svg>
                  </i>
                </a>
              </div>
            </div>
          </div>
          <div
            class="w-11/12 md:w-6/12 flex items-center justify-center order-1 md:order-2"
          >
            <p class="relative text-justify">
              Whether it’s booking a rental turntable for the weekend, shopping
              for parts or configuring the Technics of your dreams have a look
              at our web shop or come visit us.
            </p>
          </div>
        </div>
      </section>
      <section id="catalogue-preview">
        <h2 class="text-4xl text-center mb-4 py-4">Products</h2>
        <div
          class="flex justify-center w-full flex-wrap lg:w-10/12 gap-6 mx-auto mb-6"
        >
          <catalogue-preview></catalogue-preview>
        </div>
        <div
          class="flex items-center h-20 px-4 bg-white w-full lg:w-4/12 mx-auto"
        >
          <div class="flex-1">Show All Products</div>
          <router-link
            to="/shop/catalogue"
            class="flex-0 w-12 h-12 border border-black"
          >
            <i class="flex h-full items-center justify-center">
              <svg
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="8"
                viewBox="0 0 14 8"
              >
                <path
                  fill="#000"
                  fill-rule="nonzero"
                  d="M.146 3.611a.606.606 0 0 1 .437-.177h11.412L9.515.994C9.225.736 9.219.47 9.498.2c.28-.271.553-.265.82.018l3.5 3.394a.343.343 0 0 1 .147.177.65.65 0 0 1 0 .424.343.343 0 0 1-.146.177l-3.5 3.394c-.268.283-.541.29-.82.018-.28-.271-.274-.536.018-.796l2.479-2.44H.583a.577.577 0 0 1-.419-.158A.542.542 0 0 1 0 4c0-.165.049-.295.146-.389z"
                ></path>
              </svg>
            </i>
          </router-link>
        </div>
      </section>
      <section id="service">
        <h2 class="text-4xl text-center mb-4 py-4">Service Info</h2>
        <div class="lg:flex justify-between gap-6">
          <div class="lg:w-5/12 lg:px-4 mb-6">
            <p ref="service_text" class="text-justify">
              We offer every imaginable service, repair, and modification on
              Technics SL-1200 and SL-1210 models. So if something should not be
              listed here, you can just drop us a line and we will get back to
              you with an individual offer. <br />
              <br />All of our service packages are calculated in a way that you
              do not have to pay the full price on the separate services since
              we have to accept and open your deck only once. This is also true
              if you are going for a number of services from us. <br />
              <br />
              One single thing (next to cleaning your SME contacts and swapping
              out the tone arm clip) will always be at least 25€, because we
              have to accept and open every device completely.
            </p>
          </div>
          <div
            class="lg:w-6/12 mx-auto"
            :style="{ height: serviceTextHeight + 'px' }"
          >
            <div class="flex flex-col h-full border border-black">
              <div class="p-2 flex-1 overflow-hidden">
                <service-table />
              </div>
              <div class="flex items-center h-20 px-4 bg-white">
                <div class="flex-1">Service Packs & Remote Service</div>
                <router-link
                  to="/service"
                  class="flex-0 w-12 h-12 border border-black"
                >
                  <i class="flex h-full items-center justify-center">
                    <svg
                      class="icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                    >
                      <path
                        fill="#000"
                        fill-rule="nonzero"
                        d="M.146 3.611a.606.606 0 0 1 .437-.177h11.412L9.515.994C9.225.736 9.219.47 9.498.2c.28-.271.553-.265.82.018l3.5 3.394a.343.343 0 0 1 .147.177.65.65 0 0 1 0 .424.343.343 0 0 1-.146.177l-3.5 3.394c-.268.283-.541.29-.82.018-.28-.271-.274-.536.018-.796l2.479-2.44H.583a.577.577 0 0 1-.419-.158A.542.542 0 0 1 0 4c0-.165.049-.295.146-.389z"
                      ></path>
                    </svg>
                  </i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="timeline" class="py-24">
        <div class="flex justify-center items-center relative w-full">
          <div class="flex bg-white py-10 px-14">
            <span class="text-4xl pr-6">Technics Timeline</span>
            <router-link
              to="/legacy"
              class="flex-0 w-12 h-12 border border-black"
            >
              <i class="flex h-full items-center justify-center">
                <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                >
                  <path
                    fill="#000"
                    fill-rule="nonzero"
                    d="M.146 3.611a.606.606 0 0 1 .437-.177h11.412L9.515.994C9.225.736 9.219.47 9.498.2c.28-.271.553-.265.82.018l3.5 3.394a.343.343 0 0 1 .147.177.65.65 0 0 1 0 .424.343.343 0 0 1-.146.177l-3.5 3.394c-.268.283-.541.29-.82.018-.28-.271-.274-.536.018-.796l2.479-2.44H.583a.577.577 0 0 1-.419-.158A.542.542 0 0 1 0 4c0-.165.049-.295.146-.389z"
                  ></path>
                </svg>
              </i>
            </router-link>
          </div>
        </div>
      </section>
      <section>
        <h2 class="text-4xl text-center mb-4 py-4">Reviews</h2>
        <div class="md:flex gap-6">
          <re-views></re-views>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, nextTick } from 'vue';
import { CataloguePreview, ServiceTable, ReViews } from '@/components';

const service_text = ref(null);
const isLarge = inject('isLg');
let serviceTextHeight = ref(null);

onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      serviceTextHeight.value = isLarge ? service_text.value.offsetHeight : 400;
    }, 0);
  });
});
</script>

<style lang="scss">
.nav-card {
  width: calc(99.99% * (12 / 12) - (2em - 2em * (12 / 12)));

  @media only screen and (min-width: 520px) {
    width: calc(99.99% * (6 / 12) - (2em - 2em * (6 / 12)));
  }
}
</style>

<style lang="scss" scoped>
section {
  margin-bottom: 3.02vw;
}
</style>
