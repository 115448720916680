<template>
  <div class="relative">
    <figure v-if="img">
      <img class="w-full object-cover" :src="img" alt="" />
    </figure>
    <div class="flex items-center h-20 px-4 bg-white">
      <div class="flex-1">
        <slot></slot>
      </div>
      <router-link :to="routeTo" class="flex-0 w-12 h-12 border border-black">
        <i class="flex h-full items-center justify-center">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8"
            viewBox="0 0 14 8"
          >
            <path
              fill="#000"
              fill-rule="nonzero"
              d="M.146 3.611a.606.606 0 0 1 .437-.177h11.412L9.515.994C9.225.736 9.219.47 9.498.2c.28-.271.553-.265.82.018l3.5 3.394a.343.343 0 0 1 .147.177.65.65 0 0 1 0 .424.343.343 0 0 1-.146.177l-3.5 3.394c-.268.283-.541.29-.82.018-.28-.271-.274-.536.018-.796l2.479-2.44H.583a.577.577 0 0 1-.419-.158A.542.542 0 0 1 0 4c0-.165.049-.295.146-.389z"
            ></path>
          </svg>
        </i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: false,
    },
    routeTo: {
      type: String,
      required: true,
    },
  },
};
</script>
