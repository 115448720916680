<template>
  <aside
    class="new-item-box hidden mt-1 md:block md:fixed top-20 right-3 w-4/12 z-50 bg-primary border border-black"
  >
    <div class="p-3 shadow divide-y divide-black">
      <header class="flex justify-between mb-3">
        <h1 class="text-lg">Added to your cart</h1>
        <button @click="$emit('close')" class="close-button"></button>
      </header>
      <div class="flex overflow-hidden pt-3">
        <div class="w-2/12 mr-3">
          <figure
            :style="{ backgroundImage: 'url(' + item.img + ')' }"
            class="card-avatar bg-white"
          ></figure>
        </div>
        <div class="w-8/12">
          <p>
            <span>{{ item.name }}</span>
            <span class="block">{{ item.price }}&euro;</span>
          </p>
        </div>
        <div class="w-2/12 text-right">
          <span class="mr-1">QTY:</span>
          <span>{{ item.quantityAdded || 1 }}</span>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.card-avatar {
  // background-image: url('../../assets/img/mk2-model.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 66%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
</style>
