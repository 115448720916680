<template>
  <div
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="relative lg:mb-6 cursor-pointer"
  >
    <div class="relative ">
      <figure class="relative h-48 lg:h-56 p-8 bg-white mb-4 overflow-hidden">
        <img class="h-full w-full object-contain" :src="product.img" alt="" />
        <transition name="move-up">
          <template v-if="isHovering">
            <div
              class="absolute bottom-0 left-0 right-0 border-t border-black border-opacity-10 py-2 bg-white"
            >
              <button @click="handleCartAction" class="w-full text-center">
                Add to cart
              </button>
            </div>
          </template>
        </transition>
      </figure>
      <!-- <div class="absolute inset-0 bg-white opacity-10"></div> -->
    </div>
    <div class="text-sm text-gray-600 leading-4">
      {{ category }}
    </div>
    <div
      class="float-left whitespace-nowrap overflow-ellipsis overflow-hidden w-8/12 md:w-9/12"
      :title="product.name"
    >
      {{ product.name }}
    </div>
    <div class="float-right">{{ product.price }}&euro;</div>
  </div>
</template>

<script>
import { useCartControls } from '@/composable/cart';

export default {
  name: 'CatalogueCard',
  setup() {
    const { addToCart } = useCartControls();

    return {
      addToCart,
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  inject: ['showNewItem'],
  data() {
    return {
      isHovering: false,
    };
  },
  computed: {
    category: function() {
      return this.product.categories.filter(
        ({ name }) => name !== 'Configurator',
      )[0]['name'];
    },
  },
  methods: {
    handleCartAction() {
      this.addToCart(this.product);
      this.showNewItem(this.product);
    },
  },
};
</script>

<style lang="scss">
.move-up-enter-active,
.move-up-leave-active {
  transition: all 215ms ease-in-out;
}

.move-up-enter-from,
.move-up-leave-to {
  transform: translateY(100%);
}
</style>
