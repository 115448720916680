<template>
  <catalogue-card
    v-for="item in displayItems"
    :key="item.id"
    :product="item"
    class="lg:w-3/12 w-5/12"
  ></catalogue-card>
</template>

<script setup>
import { ref, watchEffect, inject } from 'vue';
import { catalogue } from '@/static/catalogue';
import CatalogueCard from '@/components/cards/CatalogueCard';

const isLarge = inject('isLg');

let index = ref(0);
let displayItems = ref([]);

let howManyItems = isLarge ? 6 : 4;

watchEffect(() => {
  displayItems.value = [];
  for (let i = index.value; i < index.value + howManyItems; i++) {
    let randomItem = catalogue[Math.floor(Math.random() * catalogue.length)];
    displayItems.value.push(randomItem);
  }
});
</script>
