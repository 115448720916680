<template>
  <div
    data-color="#F0F3B9"
    data-nav="contact"
    class="w-11/12 mx-auto pt-20 pb-8 border-t-2 border-black"
  >
    <div class="md:grid grid-cols-12">
      <div class="col-span-6 md:px-4 mb-8">
        <div class="max-w-max md:px-4">
          <div class="text-3xl md:text-4xl border-b border-black pb-1 mb-2">
            <a itemprop="telephone" href="tel:+49 30 91486168"
              >+49 30 914 861 68</a
            >
          </div>
          <div class="text-3xl md:text-4xl border-b border-black pb-1 mb-8">
            <a itemprop="email" href="mailto:info@1210berlin.de"
              >info@1210berlin.de</a
            >
          </div>
          <div
            class="text-xl"
            itemprop="adress"
            itemtype="https://schema.org/PostalAdress"
          >
            <p>
              Liberdastr. 10
              <br />
              12047 Berlin
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-3 mb-8">
        <ul class="text-xl">
          <li class="mb-4">
            <router-link :to="{ name: 'Catalogue' }">All Products</router-link>
          </li>
          <li class="mb-4">
            <router-link to="/shop">Configurator</router-link>
          </li>

          <li class="mb-4">
            <router-link to="/service">Service</router-link>
          </li>
          <li class="mb-4">
            <router-link to="/rentals">Rentals</router-link>
          </li>
          <li class="mb-4">
            <router-link to="/terms">Terms & Conditions</router-link>
          </li>
        </ul>
      </div>
      <div class="col-span-3">
        <h5 class="text-xl">Follow us:</h5>
        <ul class="mt-4 md:mt-8 mb-4 pl-1">
          <li class="inline-block mr-8">
            <a href="https://www.facebook.com/1210berlin/">
              <svg
                width="9px"
                height="18px"
                viewBox="0 0 6 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-1704.000000, -12.000000)"
                    fill="#000"
                  >
                    <g id="f" transform="translate(1704.000000, 12.000000)">
                      <path
                        d="M1.2968799,2.32395666 L1.2968799,3.97616199 L0,3.97616199 L0,5.99651365 L1.2968799,5.99651365 L1.2968799,12 L3.96095172,12 L3.96095172,5.99663084 L5.74866596,5.99663084 C5.74866596,5.99663084 5.91603365,5.02786635 5.99720635,3.96872055 L3.9709963,3.96872055 L3.9709963,2.58739545 C3.9709963,2.38093936 4.26147279,2.10320363 4.54849645,2.10320363 L6,2.10320363 L6,0 L4.02652395,0 C1.23105656,-0.000117188072 1.2968799,2.02216808 1.2968799,2.32395666 Z"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li>
          <li class="inline-block mr-8">
            <a href="https://twitter.com/1210berlinde">
              <svg
                width="18px"
                height="15px"
                viewBox="0 0 12 10"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-1740.000000, -13.000000)"
                    fill="#000"
                  >
                    <g id="t" transform="translate(1740.000000, 13.000000)">
                      <path
                        d="M11.9882341,1.1520878 C11.5470146,1.34771707 11.0736,1.48021463 10.5761561,1.53942439 C11.0841073,1.23532683 11.4728195,0.752839024 11.657122,0.17982439 C11.180722,0.461414634 10.6548,0.665970732 10.0944878,0.776868293 C9.64571707,0.298156098 9.00743415,0 8.29949268,0 C6.94138537,0 5.84016585,1.10121951 5.84016585,2.45865366 C5.84016585,2.65118049 5.86188293,2.83914146 5.90385366,3.01896585 C3.86022439,2.91629268 2.04810732,1.93718049 0.835287805,0.449414634 C0.623209756,0.812078049 0.502595122,1.23450732 0.502595122,1.68556098 C0.502595122,2.53879024 0.937170732,3.29165854 1.59638049,3.73214634 C1.1933561,3.71865366 0.814243902,3.6077561 0.482458537,3.42345366 L0.482458537,3.45421463 C0.482458537,4.64531707 1.33044878,5.63938537 2.45487805,5.86565854 C2.24885854,5.92109268 2.03162927,5.95185366 1.80684878,5.95185366 C1.64806829,5.95185366 1.49449756,5.93607805 1.34394146,5.90613659 C1.65708293,6.8837561 2.56498537,7.59465366 3.6407122,7.6140878 C2.79945366,8.27332683 1.7387122,8.6651122 0.586507317,8.6651122 C0.388039024,8.6651122 0.192526829,8.6531122 0,8.63139512 C1.08851707,9.33040976 2.38068293,9.73788293 3.76966829,9.73788293 C8.29355122,9.73788293 10.7664878,5.99072195 10.7664878,2.74103415 L10.7582634,2.42262439 C11.2414244,2.07804878 11.6594049,1.64508293 11.9882341,1.1520878 Z"
                        id="Fill-1"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li>
          <li class="inline-block mr-8">
            <a href="https://twitter.com/1210berlinde">
              <svg
                width="18px"
                height="19px"
                viewBox="0 0 12 13"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-1816.000000, -13.000000)"
                    fill="#000"
                  >
                    <g transform="translate(1806.000000, 3.814578)">
                      <g transform="translate(10.000000, 10.000000)">
                        <path
                          d="M8.4103913,0 L3.5686087,0 C1.60086957,0 0,1.60086957 0,3.5686087 L0,8.4103913 C0,10.3781304 1.60086957,11.979 3.5686087,11.979 L8.4103913,11.979 C10.3781304,11.979 11.979,10.3781304 11.979,8.4103913 L11.979,3.5686087 C11.9789783,1.60086957 10.3781087,0 8.4103913,0 Z M10.773913,8.4103913 C10.773913,9.71571739 9.71571739,10.773913 8.4103913,10.773913 L3.5686087,10.773913 C2.26328261,10.773913 1.20508696,9.71571739 1.20508696,8.4103913 L1.20508696,3.5686087 C1.20508696,2.26326087 2.26328261,1.20508696 3.5686087,1.20508696 L8.4103913,1.20508696 C9.71571739,1.20508696 10.773913,2.26326087 10.773913,3.5686087 L10.773913,8.4103913 L10.773913,8.4103913 Z"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M5.9895,2.89130435 C4.28115217,2.89130435 2.89130435,4.28115217 2.89130435,5.98947826 C2.89130435,7.69780435 4.28115217,9.08767391 5.9895,9.08767391 C7.69784783,9.08767391 9.08769565,7.69782609 9.08769565,5.98947826 C9.08769565,4.28113043 7.69784783,2.89130435 5.9895,2.89130435 Z M5.9895,7.8826087 C4.94395652,7.8826087 4.0963913,7.03504348 4.0963913,5.9895 C4.0963913,4.94395652 4.94397826,4.0963913 5.9895,4.0963913 C7.03504348,4.0963913 7.8826087,4.94395652 7.8826087,5.9895 C7.8826087,7.03502174 7.03502174,7.8826087 5.9895,7.8826087 Z"
                          fill-rule="nonzero"
                        ></path>
                        <circle
                          id="Oval"
                          cx="9.09369565"
                          cy="2.91456522"
                          r="1"
                        ></circle>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TwelveFooter',
};
</script>
