import { ref, reactive, watch, computed, provide, inject } from 'vue';
import gsap from 'gsap';

const CartSymbol = Symbol('Cart Control Key');
const cart = ref([]);

const addToCart = (item, isExclusive = false) => {
  if (item.isDefault) {
    removeCategoryFromCart(item._category);
    return;
  }

  let done;

  for (var i = 0; i < cart.value.length; i++) {
    if (cart.value[i].id === item.id && !isExclusive) {
      cart.value[i].quantity += 1;

      done = true;

      break;
    } else if (isExclusive && cart.value[i]._category === item._category) {
      cart.value[i] = item;

      done = true;

      break;
    }
  }

  if (!done) {
    item.quantity = 1;
    cart.value.push(item);
  }
};

const removeFromCart = ({ id }) => {
  let _cart = cart.value.filter((product) => product.id !== id);

  cart.value = _cart;
};

function removeCategoryFromCart(category) {
  let _cart = cart.value.filter(({ _category }) => _category !== category);

  cart.value = _cart;
}

const getItemByCategory = (category) =>
  cart.value.filter(({ _category }) => _category === category);

const _configurationTotal = computed(() =>
  cart.value
    .filter((product) => product.isConfigurator)
    .reduce((acc, cur) => {
      return acc + Number(cur.price);
    }, 0),
);

const tweenTotal = reactive({
  number: _configurationTotal.value,
});

watch(_configurationTotal, (newValue) => {
  gsap.to(tweenTotal, { duration: 0.5, number: newValue });
});
const configurationTotal = computed(() => tweenTotal.number.toFixed(0));

// Expose API

export function provideCartControls() {
  const controls = provide(
    CartSymbol,
    {
      addToCart,
      removeFromCart,
      itemsInCart: () => cart.value.length,
      getItemByCategory,
      isInCart: (id) => cart.value.some((prod) => prod.id === id),
      activeModel: () => cart.value.find((prod) => prod._category === 'Model'),
      configurationTotal,
    },
    null,
  );

  return controls;
}

export function useCartControls() {
  const controls = inject(CartSymbol);

  return controls;
}
