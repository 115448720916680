// Borrowed from @Nuxt

function defaultConditional() {
  return true;
}

const getIncludes = (includes) =>
  includes.map((inc) => document.querySelector(inc));

function directive(e, el, binding) {
  const handler =
    typeof binding.value === 'function' ? binding.value : binding.value.handler;

  const isActive =
    (typeof binding.value === 'object' && binding.value.closeConditional) ||
    defaultConditional;

  if (!e || !isActive(e)) return;

  const elements =
    (typeof binding.value === 'object' &&
      binding.value.include &&
      getIncludes(binding.value.include)) ||
    (() => [])();

  elements.push(el);

  !elements.some((el) => el.contains(e.target)) &&
    setTimeout(() => {
      isActive(e) && handler && handler(e);
    }, 0);
}

export const ClickOutside = {
  mounted(el, binding) {
    const onClick = (e) => directive(e, el, binding);

    const app = document.body;

    app.addEventListener('click', onClick, true);
    el._clickOutside = onClick;
  },

  unmounted(el) {
    if (!el._clickOutside) return;

    const app = document.body;

    app.removeEventListener('click', el._clickOutside, true);

    delete el._clickOutside;
  },
};
