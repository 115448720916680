<template>
  <component :is="LayoutMain">
    <template #header>
      <twelve-header></twelve-header>
    </template>
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component"></component>
      </transition>
    </router-view>
    <template #footer>
      <twelve-footer></twelve-footer>
    </template>
  </component>
  <transition name="appear">
    <template v-if="newItemBox.show">
      <cart-new-item
        @close="newItemBox.show = false"
        :item="newItemBox.item"
      ></cart-new-item>
    </template>
  </transition>
</template>

<script>
import { LayoutMain, TwelveHeader, TwelveFooter } from '@/layout';
import { CartNewItem } from '@/components';
import { provideCartControls } from '@/composable/cart';

const isLarge = () => {
  if (typeof document === 'undefined') return 0; // SSR
  console.log(
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
  );
  return (
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >
    1024
  );
};

export default {
  setup() {
    provideCartControls();
    return { LayoutMain };
  },
  components: {
    LayoutMain,
    TwelveHeader,
    TwelveFooter,
    CartNewItem,
  },
  provide() {
    return {
      isLg: isLarge(),
      showNewItem: this.showNewItem,
    };
  },
  data() {
    return {
      newItemBox: {
        show: false,
        item: {},
      },
    };
  },
  methods: {
    showNewItem(item) {
      this.newItemBox.item = item;
      this.newItemBox.show = true;

      setTimeout(() => {
        this.newItemBox.show = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss">
* {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(88, 95, 95);
  }
  &::-webkit-scrollbar-thumb {
    background: #000;
  }
}

.appear-enter-active,
.appear-leave-active {
  transition: all 300ms ease;
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
  right: 20px;
}
</style>
