<template>
  <div class="flex justify-between items-end">
    <div>
      <p class="text-gray-600 text-sm">Sort by</p>
      <div class="flex items-center">
        <span class="mr-2">Relevance</span>
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="5"
            viewBox="0 0 8 5"
          >
            <g>
              <g>
                <path
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="20"
                  stroke-width="1.6"
                  d="M1 1v0l3.233 3v0L7 1v0"
                />
              </g>
            </g></svg
        ></span>
      </div>
    </div>
    <div class="text-center">
      <div class="text-gray-600 text-sm">{{ lengthList }} products</div>
      <div class="text-sm" v-if="category.length">
        <span class="mr-3">{{ category }}</span>
        <button
          @click.stop="$emit('clearCategoryFilter')"
          class="underline tracking-widest"
        >
          CLEAR
        </button>
      </div>
    </div>
    <div>
      <button @click.stop="$emit('changeListView', false)" class="mr-3">
        <svg
          width="17px"
          height="17px"
          viewBox="0 0 17 17"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="02.-Collection"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="XS---Collection---1-row"
              transform="translate(-163.000000, -277.000000)"
              :fill="$attrs.isTight ? '#999' : '#000'"
            >
              <g
                id="Group-24-Copy"
                transform="translate(187.000000, 285.500000) rotate(-90.000000) translate(-187.000000, -285.500000) translate(178.000000, 261.000000)"
              >
                <path
                  d="M8.5,9.5 L8.5,17.5 L0.5,17.5 L0.5,9.5 L8.5,9.5 Z M17.5,9.5 L17.5,17.5 L9.5,17.5 L9.5,9.5 L17.5,9.5 Z M8.5,0.5 L8.5,8.5 L0.5,8.5 L0.5,0.5 L8.5,0.5 Z M17.5,0.5 L17.5,8.5 L9.5,8.5 L9.5,0.5 L17.5,0.5 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button @click.stop="$emit('changeListView', true)">
        <svg
          width="21px"
          height="17px"
          viewBox="0 0 21 17"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="02.-Collection"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="XL---Collection---Expanded-Filter"
              transform="translate(-1360.000000, -501.000000)"
              :fill="$attrs.isTight ? '#000' : '#999'"
            >
              <g
                id="Group-24"
                transform="translate(1354.500000, 509.500000) rotate(-90.000000) translate(-1354.500000, -509.500000) translate(1346.000000, 483.000000)"
              >
                <path
                  d="M8,46.5 L8,52.5 L0,52.5 L0,46.5 L8,46.5 Z M17,46.5 L17,52.5 L9,52.5 L9,46.5 L17,46.5 Z M8,39.5 L8,45.5 L0,45.5 L0,39.5 L8,39.5 Z M17,39.5 L17,45.5 L9,45.5 L9,39.5 L17,39.5 Z M8,32.5 L8,38.5 L0,38.5 L0,32.5 L8,32.5 Z M17,32.5 L17,38.5 L9,38.5 L9,32.5 L17,32.5 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalogueListHeader',
  emits: ['clearCategoryFilter'],
  props: {
    category: {
      type: String,
    },
    lengthList: {
      type: Number,
      required: true,
    },
  },
};
</script>
